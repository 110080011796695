import React from 'react';
import PropTypes from 'prop-types';
import '../../styles/Common/loading.scss';

const LoadingSpinner = ({ isLoading, text }) => (
  isLoading ? (
    <div className="faded-background">
      <div className="loader-container">
        <div className="la-ball-clip-rotate-multiple la-2x">
          <div />
          <div />
        </div>
        <div className="loader-text">{text}</div>
      </div>
    </div>
  ) : <></>
);

LoadingSpinner.propTypes = {
  isLoading: PropTypes.bool.isRequired,
  text: PropTypes.string,
};

export default LoadingSpinner;
