import React from "react"

import Layout from "../layouts/index"
import SEO from "../components/seo"
import LoadingSpinner from "../components/Common/LoadingSpinner"

const NotFoundPage = () => (
  <Layout>
    <div style={{ height: '100vh' }} />
    <LoadingSpinner isLoading={true} />
  </Layout>
)

export default NotFoundPage
